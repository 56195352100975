h1, h2 {
  margin: 0;
}

/*
 * Setting theming color classes
 */
.primary {
  &.color {
    color: var(--ml-primary-color, grey);
  }

  &.background {
    background-color: var(--ml-primary-color, grey);
  }
}

.secondary {
  &.color {
    color: var(--ml-secondary-color, grey);
  }

  &.background {
    background-color: var(--ml-secondary-color, grey);
  }
}

/*
* Setting the layout classes
*/
.row {
  flex-direction: row;

  &.center {
    justify-content: center;

    &.text-center {
      text-align: center;
    }

    h1 {
      font-size: 2rem;
      text-align: center;
      overflow-wrap: anywhere;
    }
  }
}

.column {
  flex-direction: column;
  width: 100%;

  &.center {
    align-items: center;
  }
}

.row, .column {
  display: flex;

  &.gap-1 {
    gap: 1rem
  }

  .item {
    flex: 1;

    &.one {
      flex: 1;
    }

    &.two {
      flex: 2;
    }

    &.three {
      flex: 3;
    }

    &.four {
      flex: 4;
    }

    &.five {
      flex: 5;
    }

    &.six {
      flex: 6;
    }

    &.seven {
      flex: 7;
    }

    &.height {
      flex: 8;
    }

    &.nine {
      flex: 9;
    }

    &.ten {
      flex: 10;
    }

    &.eleven {
      flex: 11;
    }
  }
}


@mixin margin-top($rem) {
  &-#{$rem} {
    margin-top: #{$rem}rem;
  }
}

@mixin margin-bottom($rem) {
  &-#{$rem} {
    margin-bottom: #{$rem}rem;
  }
}

.space-top {
  @for $_ from 1 through 10 {
    @include margin-top($_)
  }
}

.space-bot {
  @for $_ from 1 through 10 {
    @include margin-bottom($_)
  }
}

/*
 * Setting default font-size to 10px so 1rem = 10px in default
 */
html {
  color: var(--ml-theme-primary-contrast-50);

  --mdc-typography-button-font-size: 1.6rem;
  --mdc-typography-button-letter-spacing: 0.1rem;
  --mdc-shape-small: 0.4rem;
}

/*
 * Correcting Material for our project
 */
.mat-mdc-form-field {
  width: 100%;

  .mat-mdc-form-field-subscript-wrapper {
    display: flex;

    .mat-mdc-form-field-error-wrapper, .mat-mdc-form-field-hint-wrapper {
      position: relative;
      width: 100%;
      padding: 0 var(--mdc-shape-small);
    }
  }

  &-icon-prefix>mat-icon.mat-icon, &-icon-suffix>mat-icon.mat-icon {
    padding: 0 1.2rem;
  }
}

.mat-mdc-form-field-infix {
  display: flex;
}

ml-number-input {
  .mat-mdc-form-field-infix {
    width: unset;
  }

  .mdc-text-field--invalid {
    button.mat-mdc-outlined-button:not(:disabled) {
      border-color: var(--mdc-theme-error);
    }

    .mat-mdc-outlined-button:not(:disabled) {
      color: var(--mdc-theme-error);
    }
  }
}

.hide-iban-input{
  .mdc-notched-outline{
    &__leading, &__notched, &__trailing{
      border: none;
    }
  }
}

.mdc-text-field {
  &--outlined:not(&--disabled, &--invalid) .mdc-notched-outline {
    &__leading, &__notch, &__trailing {
      border-color: var(--mdc-outlined-text-field-outline-color);
    }
  }
}

.hide-input.ml-focused {
  .mdc-text-field {
    &--outlined:not(&--disabled, &--invalid) .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        border-width: 0.2rem;
        border-color: var(--ml-theme-primary-700);
      }
    }
  }
}

.mat-mdc-radio-group {
  gap: 1.2rem;

  .mdc-form-field {
    display: flex;
    cursor: inherit;

    &>label {
      padding: 0;
      cursor: inherit;
      display: flex;
      height: 100%;
      flex-direction: column;
      flex: 1;
    }
  }

  .mat-mdc-radio-button {
    display: grid;
    min-height: 4rem;
    border-radius: var(--mdc-shape-small);
    box-sizing: border-box;

    &.radio-select {
      &--disabled {
        cursor: default;
        border: 0.1rem solid #0000000f;
      }
    }
  }
}

ml-radio-cards .mat-mdc-radio-group {
  display: flex;
  flex-direction: column;

  .mat-mdc-radio-button {
    padding: 1.6rem;
    box-shadow: 0 .4rem 1rem 0 var(--ml-theme-shadow);

    &.mat-accent {
      --mdc-radio-selected-icon-color: var(--ml-theme-primary-700);
      --mdc-radio-selected-pressed-icon-color: var(--ml-theme-primary-700);
      --mdc-radio-selected-focus-icon-color: var(--ml-theme-primary-700);
      --mdc-radio-selected-hover-icon-color: var(--ml-theme-primary-700);
      --mat-radio-checked-ripple-color: var(--ml-theme-primary-700);
    }

    .mdc-radio {
      padding: 0;

      .mdc-radio__native-control:enabled:checked+.mdc-radio__background {
        --mat-radio-checked-ripple-color: var(--ml-theme-primary-700);
        --mdc-radio-selected-icon-color: var(--ml-theme-primary-700);

        .mdc-radio__inner-circle {
          border-color: white;
        }

        .mdc-radio__outer-circle {
          border-width: .5rem;;

          --mdc-radio-selected-icon-color: var(--ml-theme-primary-700);
        }
      }

      .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background {
        .mdc-radio__outer-circle {
          border-width: .1rem;
          opacity: 0.7;
        }
      }
    }
  }

  .mdc-form-field {
    align-items: flex-start;
    gap: .8rem;
  }
}

.ml-insurance-select.mat-mdc-radio-group {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .mdc-form-field {
    position: relative;
    display: flex;
  }

  .mdc-radio {
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
  }

  .mdc-form-field>label {
    padding-left: 0;
    width: 100%;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--ml-theme-primary-600);
    background-color: var(--ml-theme-primary-600);
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    width: 50%;
    height: 50%;
    top: 0.3rem;
    left: 0.3rem;
    border: white solid 0.7rem;
  }

  .mdc-radio__background {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 2rem;
    height: 2rem;
    background-color: white;
    border-radius: 2.5rem;
  }

  .insurance-box .mat-mdc-radio-checked {
    .no-insurance:not(.no-insurance-person) {
      background-color: var(--ml-theme-primary-50);
      color: var(--ml-theme-primary-contrast-50);
    }

    .no-insurance-person {
      background-color: var(--ml-theme-primary-200);
      color: var(--ml-theme-primary-contrast-200);
    }

    ml-insurance-card .insurance-card {
      background-color: var(--ml-theme-primary-50);
      color: var(--ml-theme-primary-contrast-50);

      .covered-risks {
        background-color: var(--ml-theme-primary-200);
        color: var(--ml-theme-primary-contrast-200);
      }

      .bottom .insurance-details .insurance-price {
        background-color: var(--ml-theme-primary-200);
        color: var(--ml-theme-primary-contrast-200);
      }
    }
  }
}

ml-radio-select .mat-mdc-radio-group, ml-yes-no-input .mat-mdc-radio-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;

  .mdc-form-field > label {
    align-items: center;
    text-align: center;
    justify-content: center;

    .mat-icon {
      font-size: 6rem;
      height: unset;
      width: unset;
    }
  }

  .mat-mdc-radio-button {
    padding: 0.5rem;
    height: 5.6rem;

    &.mat-mdc-radio-checked {
      background-color: var(--ml-theme-primary-50);
    }

    &:not(.radio-select--disabled) {
      border: 0.1rem solid var(--mdc-outlined-text-field-outline-color);
      cursor: pointer;

      &.cdk-focused {
        border-color: var(--ml-theme-primary-700);
        border-style: double;
      }

      &.mat-mdc-radio-checked {
        border: 0.15rem solid var(--ml-theme-primary-700);

        --mat-checkbox-label-text-color: var(--ml-theme-primary-700);

        &.cdk-focused {
          border-color: var(--ml-theme-primary-700);
        }
      }
    }

    .mdc-radio {
      position: absolute;
      opacity: 0;
    }
  }
}

ml-radio-cards .mat-mdc-radio-group .mat-mdc-radio-button:not(.radio-cards--disabled) {
  cursor: pointer;
}

ml-radio-select .mat-mdc-radio-group .mat-mdc-radio-button:not(.radio-select--disabled):hover {
  background-color: var(--ml-theme-primary-50);
}

ml-radio-select .mat-mdc-radio-group .mat-mdc-radio-button.mat-mdc-radio-checked:not(.radio-select--disabled) {
  &:hover, &.mat-mdc-radio-checked {
    background-color: var(--ml-theme-primary-50);
    color: var(--ml-theme-primary-700);
  }
}

.mat-mdc-radio-group .mdc-form-field>label:has(>.mat-icon) {
  justify-content: flex-start;
  padding: 1rem;
  box-sizing: border-box;
}

ml-radio-select .mat-mdc-radio-group .mat-mdc-radio-button:not(.radio-select--disabled).cdk-focused>.mdc-form-field>label {
  color: var(--ml-theme-primary-700);
}

.mat-mdc-radio-group.icon-choices .mdc-form-field {
  height: fit-content;
  min-height: 100%;
}

form {
  .mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple,
  .mdc-checkbox:hover .mdc-checkbox__native-control:checked ~ .mdc-checkbox__ripple,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background,
  .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-icon-color: var(--ml-theme-primary-700);
    --mdc-checkbox-selected-focus-icon-color: var(--ml-theme-primary-700);
    --mdc-checkbox-selected-pressed-icon-color: var(--ml-theme-primary-700);
    --mdc-checkbox-selected-hover-state-layer-color: var(--ml-theme-primary-700);
    --mdc-checkbox-selected-hover-icon-color: var(--ml-theme-primary-700);
    --mdc-checkbox-selected-focus-state-layer-color: var(--ml-theme-primary-700);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--ml-theme-primary-700);
    --mdc-checkbox-selected-checkmark-color: var(--ml-theme-primary-contrast-700);
  }
}

.ml {
  &-animations {
    &-set-disappear-top {
      animation: 1.5s ease-in disappear-top forwards;
    }

    &-set-appear-top {
      animation: 1.5s ease-in appear-top forwards;
    }

    @keyframes disappear-top {
      from {
        margin-top: 0;
        display: block;
      }

      to {
        margin-top: -70rem;
        display: none;
      }
    }

    @keyframes appear-top {
      from {
        margin-top: -70rem;
        display: none;
      }

      to {
        margin-top: 0;
        display: block;
      }
    }
  }
}

.sticky-wrapper {
  z-index: 2;

  .mdc-button {
    &--unelevated {
      padding: 2rem 3rem;
      font-weight: 900;
    }
  }
}

.autocomplete-panel{
  width: auto !important;
  max-width: 90%;
}

.justify-sticky-buttons-center {
  flex: 1;
  display: flex;
  justify-content: center;
}

button.mdc-button {
  width: 100%;
  min-height: 4rem;
  font-weight: 700;

  .mdc-button__label {
    position: absolute;
    inset: 0;
    align-content: center;

    > * {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      &.ml-row {
        flex-direction: row;
      }
    }
  }
}

.mat-mdc-checkbox .mdc-form-field {
  align-items: flex-start;

  label {
    padding-top: 0.7rem;
  }
}

a:not(.do-not-display-as-a-link) {
  &:link, &:visited {
    text-decoration: underline;
    color: var(--ml-theme-primary-500);
  }
}
