@import '../../../node_modules/ngx-mobilite-material/src/lib/css/fonts';
@import '../../../node_modules/ngx-mobilite-material/src/lib/css';

h1, h2 {
  margin: 0;
}

/*
 * Setting theming color classes
 */
.primary {
  &.color {
    color: var(--fol-primary-color, grey);
  }

  &.background {
    background-color: var(--fol-primary-color, grey);
  }
}

.secondary {
  &.color {
    color: var(--fol-secondary-color, grey);
  }

  &.background {
    background-color: var(--fol-secondary-color, grey);
  }
}

/*
* Setting the layout classes
*/
.row {
  flex-direction: row;

  &.center {
    justify-content: center;

    &.text-center {
      text-align: center;
    }

    h1 {
      font-size: 2rem;
      text-align: center;
      overflow-wrap: anywhere;
    }
  }
}

.column {
  flex-direction: column;
  width: 100%;

  &.center {
    align-items: center;
  }
}

.row, .column {
  display: flex;

  &.gap-1 {
    gap: 1rem
  }

  .item {
    flex: 1;

    &.one {
      flex: 1;
    }

    &.two {
      flex: 2;
    }

    &.three {
      flex: 3;
    }

    &.four {
      flex: 4;
    }

    &.five {
      flex: 5;
    }

    &.six {
      flex: 6;
    }

    &.seven {
      flex: 7;
    }

    &.height {
      flex: 8;
    }

    &.nine {
      flex: 9;
    }

    &.ten {
      flex: 10;
    }

    &.eleven {
      flex: 11;
    }
  }
}


@mixin margin-top($rem) {
  &-#{$rem} {
    margin-top: #{$rem}rem;
  }
}

@mixin margin-bottom($rem) {
  &-#{$rem} {
    margin-bottom: #{$rem}rem;
  }
}

.space-top {
  @for $_ from 1 through 10 {
    @include margin-top($_)
  }
}

.space-bot {
  @for $_ from 1 through 10 {
    @include margin-bottom($_)
  }
}

/*
 * Setting default font-size to 10px so 1rem = 10px in default
 */
html {
  color: var(--ml-theme-primary-contrast-50, #0B3042);

  --mdc-typography-button-font-size: 1.6rem;
  --mdc-typography-button-letter-spacing: 0.1rem;
  --mdc-shape-small: 0.4rem;
}

/*
 * Correcting Material for our project
 */
.mat-mdc-form-field {
  width: 100%;

  .mat-mdc-form-field-subscript-wrapper {
    display: flex;

    .mat-mdc-form-field-error-wrapper, .mat-mdc-form-field-hint-wrapper {
      position: relative;
      width: 100%;
      padding: 0 var(--mdc-shape-small);
    }
  }

  &-icon-prefix>mat-icon.mat-icon, &-icon-suffix>mat-icon.mat-icon {
    padding: 0 1.2rem;
  }
}

.mat-mdc-form-field-infix {
  display: flex;
}

.hide-iban-input{
  .mdc-notched-outline{
    &__leading, &__notched, &__trailing{
      border: none;
    }
  }
}

.mdc-text-field {
  &--outlined:not(&--disabled, &--invalid) .mdc-notched-outline {
    &__leading, &__notch, &__trailing {
      border-color: var(--ml-theme-primary-border);
    }
  }
}

.hide-input.fol-focused {
  .mdc-text-field {
    &--outlined:not(&--disabled, &--invalid) .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        border-width: 0.2rem;
        border-color: var(--ml-theme-primary-500);
      }
    }
  }
}

.fol {
  &-animations {
    &-set-disappear-top {
      animation: 1.5s ease-in disappear-top forwards;
    }

    &-set-appear-top {
      animation: 1.5s ease-in appear-top forwards;
    }

    @keyframes disappear-top {
      from {
        margin-top: 0;
        display: block;
      }

      to {
        margin-top: -70rem;
        display: none;
      }
    }

    @keyframes appear-top {
      from {
        margin-top: -70rem;
        display: none;
      }

      to {
        margin-top: 0;
        display: block;
      }
    }
  }
}

.sticky-wrapper {
  z-index: 2;

  .mdc-button {
    &--unelevated {
      padding: 2rem 3rem;
      font-weight: 900;
    }
  }
}

[income]{
  .mat-mdc-form-field-subscript-wrapper::before{
    height: 0.4rem;
  }
}

.autocomplete-panel{
  width: auto !important;
  max-width: 90%;
}

.justify-sticky-buttons-center {
  flex: 1;
  display: flex;
  justify-content: center;
}

.fol-legal-notice {
  font-size: 1.2rem;

  a{
    text-decoration: underline;
  }
}

