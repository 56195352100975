@use '@angular/material' as mat;
@include mat.core;

:root {
  --ml-theme-primary-50: #E0F2FA;
  --ml-theme-primary-100: #B3DEF3;
  --ml-theme-primary-200: #80C8EB;
  --ml-theme-primary-300: #4DB1E2;
  --ml-theme-primary-400: #26A1DC;
  --ml-theme-primary-500: #0090D6;
  --ml-theme-primary-600: #0088D1;
  --ml-theme-primary-700: #007DCC;
  --ml-theme-primary-800: #0073C6;
  --ml-theme-primary-900: #0061BC;
  --ml-theme-primary-A100: #E5F1FF;
  --ml-theme-primary-A200: #B2D5FF;
  --ml-theme-primary-A400: #7FB9FF;
  --ml-theme-primary-A700: #7FB9FF;
  --ml-theme-primary-contrast-50: #0B3042;
  --ml-theme-primary-contrast-100: #0B3042;
  --ml-theme-primary-contrast-200: #0B3042;
  --ml-theme-primary-contrast-300: #0B3042;
  --ml-theme-primary-contrast-400: #0B3042;
  --ml-theme-primary-contrast-500: #B3DEF3;
  --ml-theme-primary-contrast-600: #B3DEF3;
  --ml-theme-primary-contrast-700: #B3DEF3;
  --ml-theme-primary-contrast-800: #B3DEF3;
  --ml-theme-primary-contrast-900: #B3DEF3;
  --ml-theme-primary-contrast-A100: #0B3042;
  --ml-theme-primary-contrast-A200: #0B3042;
  --ml-theme-primary-contrast-A400: #0B3042;
  --ml-theme-primary-contrast-A700: #0B3042;
  --ml-theme-primary-border: #0B304299;
  --ml-theme-holder: #F6EEF6;
  --ml-theme-co-holder: #FEF4E5;
  --ml-theme-housing: #E8F5F3;
  --mdc-outlined-text-field-outline-color: #00000061;
}

$dynamic-theme-primary: (
  50: var(--ml-theme-primary-50, #E0F2FA),
  100: var(--ml-theme-primary-100, #B3DEF3),
  200: var(--ml-theme-primary-200, #80C8EB),
  300: var(--ml-theme-primary-300, #4DB1E2),
  400: var(--ml-theme-primary-400, #26A1DC),
  500: var(--ml-theme-primary-500, #0090D6),
  600: var(--ml-theme-primary-600, #0088D1),
  700: var(--ml-theme-primary-700, #007DCC),
  800: var(--ml-theme-primary-800, #0073C6),
  900: var(--ml-theme-primary-900, #0061BC),
  A100: var(--ml-theme-primary-A100, #E5F1FF),
  A200: var(--ml-theme-primary-A200, #B2D5FF),
  A400: var(--ml-theme-primary-A400, #7FB9FF),
  A700: var(--ml-theme-primary-A700, #7FB9FF),
  contrast: (
    50: var(--ml-theme-primary-contrast-50, #0B3042),
    100: var(--ml-theme-primary-contrast-100, #0B3042),
    200: var(--ml-theme-primary-contrast-200, #0B3042),
    300: var(--ml-theme-primary-contrast-300, #0B3042),
    400: var(--ml-theme-primary-contrast-400, #0B3042),
    500: var(--ml-theme-primary-contrast-500, #B3DEF3),
    600: var(--ml-theme-primary-contrast-600, #B3DEF3),
    700: var(--ml-theme-primary-contrast-700, #B3DEF3),
    800: var(--ml-theme-primary-contrast-800, #B3DEF3),
    900: var(--ml-theme-primary-contrast-900, #B3DEF3),
    A100: var(--ml-theme-primary-contrast-A100, #0B3042),
    A200: var(--ml-theme-primary-contrast-A200, #0B3042),
    A400: var(--ml-theme-primary-contrast-A400, #0B3042),
    A700: var(--ml-theme-primary-contrast-A700, #0B3042)
  )
);
$my-primary: mat.define-palette($dynamic-theme-primary);
$my-accent: mat.define-palette($dynamic-theme-primary, A200, A100, A400);
$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($my-theme);
@include mat.form-field-density(-5);

// Temporary fix as there is a bug in Angular Material 15.2.5 Button Theming mixins
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: var(--ml-theme-primary-contrast-500, #B3DEF3);
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: var(--ml-theme-primary-contrast-A200, #0B3042);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: var(--ml-theme-primary-contrast-500, #B3DEF3);
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-label-text-color: var(--ml-theme-primary-contrast-A200, #0B3042);
}

